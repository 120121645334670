import { mapHelper } from 'common/utils'

// 状态
const status = [
  {
    text: '是',
    value: 1
  },
  {
    text: '否',
    value: 0
  }
]
const ruleData = [
  [
    {
      title: '户均订单数',
      context: '统计周期内，总订单数/交付户数，四舍五入；'
    },
    {
      title: '及时接单率',
      context: '每月及时接单率之和/累计月数；'
    },
    {
      title: '工单完成率',
      context: '（维修单及时完成数+投诉单及时完成数）/（维修单数+投诉单数）；'
    },
    {
      title: '工单评价率',
      context: '（投诉单有效评价数+维修单有效评价数）/（有效投诉单数+有效维修单数）；'
    },
    {
      title: '服务质量',
      context: '评价时间在统计周期内的园区订单，服务质量平均分；'
    },
    {
      title: '服务速度',
      context: '评价时间在统计周期内的园区订单，服务速度平均分；'
    },
    {
      title: '满意度',
      context: '（服务质量+服务速度）/2；'
    },
    {
      title: '快递及时领取率',
      context: '快递及时领取数/有效快递录入数；'
    },
    {
      title: '快递二维码领取率',
      context: '快递二维码领取数/快递领取数；'
    },
    {
      title: '绿城驿站分流率',
      context: '快递箱录入数/快递录入数；'
    },
    {
      title: '寄存及时领取率',
      context: '寄存及时领取数/有效寄存录入数'
    },
    {
      title: '寄存二维码领取率',
      context: '寄存二维码领取数/寄存领取数；'
    },
    {
      title: '鹰眼及时审核率',
      context: '每月及时审核率之和/累计月数；'
    },
    {
      title: 'APP注册审核及时率',
      context: '及时（24小时）通过业主申请的次数/申请总数；'
    },
    {
      title: '实名认证率',
      context: '实名认证数/已注册用户数；'
    },
    {
      title: '实名及时审核率',
      context: '及时（24小时）实名及时审核数/实名申请数；'
    },
    {
      title: '访客二维码使用率',
      context: '访客二维码使用数/访客总数；'
    },
    {
      title: '园区巡查率',
      context: '每月园区巡查率之和/累计月数；'
    },
    {
      title: '活跃率',
      context: '每月活跃率之和/累计月数；'
    },
    {
      title: '安装率',
      context: '统计周期内，注册业主数/交付户数；'
    }
  ],
  [
    {
      title: '总订单数',
      context:
        '统计周期内，商家订单（他营）、园区订单（自营）的总和，需剔除用户房号为生活服务中心的订单；'
    },
    {
      title: '投诉单数',
      context:
        '下单时间在统计周期内的投诉(服务子分类)订单数，剔除状态为待处理且下单时间距统计周期末小于等于30天的订单（需剔除用户房号为生活服务中心的订单）；'
    },
    {
      title: '维修单数',
      context:
        '下单时间在统计周期内的维修订单数，剔除状态为待处理且下单时间距统计周期末小于等于30天的订单（需剔除用户房号为生活服务中心的订单）；'
    },
    {
      title: '投诉单及时完成数',
      context:
        '下单时间在统计周期内，状态为已处理或已评价且完成处理时间距下单时间小于等于30天的投诉单数（需剔除用户房号为生活服务中心的订单）；'
    },
    {
      title: '维修单及时完成数',
      context:
        '下单时间在统计周期内，状态为已处理或已评价且完成处理时间距下单时间小于等于30天的维修单数（需剔除用户房号为生活服务中心的订单）；'
    },
    {
      title: '有效维修单数',
      context:
        '已处理或已完成状态、处理时间在统计周期内的维修订单，，删除14天内已处理但未评价的订单，只统计幸福绿城App下单的订单数(需剔除用户房号为生活服务中心的订单、剔除物管、剔除后台)；'
    },
    {
      title: '有效投诉单数',
      context:
        '已处理或已完成状态、处理时间在统计周期内的投诉订单，删除14天内已处理但未评价的订单，只统计幸福绿城App下单的订单数(需剔除用户房号为生活服务中心的订单、剔除物管、剔除后台)；'
    },
    {
      title: '投诉单有效评价数',
      context:
        '已完成状态、处理时间及评价时间在统计周期内的投诉订单，且不是系统默认评价；只统计幸福绿城App下单的订单(需剔除用户房号为生活服务中心的订单、剔除物管、剔除后台)；'
    },
    {
      title: '维修单有效评价数',
      context:
        '已完成状态、处理时间及评价时间在统计周期内的维修订单，且不是系统默认评价；只统计幸福绿城App下单的订单(需剔除用户房号为生活服务中心的订单、剔除物管、剔除后台)；'
    },
    {
      title: '有效快递录入数',
      context:
        '录入时间在统计周期内，状态为有效，“未删除”，剔除“未领取，且录入时间距周期末小于等于7天”的快递；'
    },
    {
      title: '快递及时领取数',
      context: '录入时间在统计周期内，且领取时间-录入时间小于7天，且状态为“有效”的快递数；'
    },
    {
      title: '快递领取数',
      context: '领取时间在统计周期内，且状态为“有效”的快递数含快递箱领取的快递数；'
    },
    {
      title: '快递二维码领取数',
      context: '领取时间在统计周期内，状态为“有效”且操作方式为扫码查询含快递箱录入的快递数；'
    },
    {
      title: '快递录入数',
      context: '录入时间在统计周期内的快递数（剔除“已删除”，剔除状态为“无效”）；'
    },
    {
      title: '快递箱录入数',
      context: '录入时间在统计周期内，操作方式为快递箱的，且状态为“有效”的快递数；'
    },
    {
      title: '有效寄存录入数',
      context: '录入时间在统计周期内的寄存物品数，剔除状态为未领取且录入时间距统计周期末小于7天的；'
    },
    {
      title: '寄存及时领取数',
      context: '录入时间在统计周期内，且领取时间-录入时间小于7天的寄存物品数；'
    },
    {
      title: '寄存领取数',
      context: '领取时间在统计周期内的寄存物品数；'
    },
    {
      title: '寄存二维码领取数',
      context:
        '领取时间在统计周期内，且操作方式为扫码查询的寄存物品数；需区分领取方式（只取二维码领取）；'
    },
    {
      title: '实名申请数',
      context: '统计周期内，业主申请实名认证的次数；'
    },
    {
      title: '实名及时审核数',
      context: '实名认证申请时间及审核完成时间都在统计周期内，且审核时间小于等于24小时的次数；'
    },
    {
      title: '访客总数',
      context: '统计周期内，到访的访客数；'
    },
    {
      title: '访客二维码使用数',
      context: '统计周期内，进入方式为二维码验证的到访客数；'
    },
    {
      title: '注册业主数',
      context:
        '截止到统计周期末，幸福绿城app上通过房号审核的用户总数，用户拥有2个房号，记为2个用户。剔除地址为“生活服务中心”的用户数；'
    },
    {
      title: '有效园区订单数',
      context:
        '统计周期内，已支付的自营商家的订单总数，包括线上支付已支付（按下单时间）、线下支付已处理（按处理时间）订单，只统计幸福绿城App下单的订单数(需剔除用户房号为生活服务中心的订单、剔除物管、剔除后台)，剔除售后的订单（只要提交售后就算，不管售后申请有没有通过）；'
    },
    {
      title: '有效园区订单及时接单数',
      context:
        '统计周期内，有效园区订单数中，园区工作时间内15分钟接单数+工作时间外次日上班时间开始的15分钟内接单数；'
    },
    {
      title: '鹰眼申请数',
      context: '统计周期内，业主申请开通鹰眼的总数；'
    },
    {
      title: '鹰眼及时审核数',
      context: '申请时间及审核完成时间都在统计周期内，且审核时间小于等于24小时；'
    },
    {
      title: '活跃用户数',
      context:
        '业主在A园区有2套房，B园区有1套房，业主打开App后，A园区活跃用户数+2，B园区活跃用户数+0。同一天内，业主切换到B园区，B园区活跃用户数+1，A园区活跃用户数不变动）。即用户活跃需要与房号关联。统计周期内，剔除地址为生活服务中心的用户，剔除待审核和审核未通过的用户；'
    },
    {
      title: '注册业主数',
      context:
        '截止到统计周期末，幸福绿城app上通过房号审核的用户总数，用户拥有2个房号，记为2个用户。剔除地址为“生活服务中心”的用户数；'
    },
    {
      title: '实名认证数',
      context:
        '截止当前已实名认证的总用户数（需剔除生活服务中心），如一个园区多个房号，则算作多个；'
    }
  ]
]
// 季度
const seasonNum = [
  {
    text: '1季度',
    value: 1
  },
  {
    text: '2季度',
    value: 2
  },
  {
    text: '3季度',
    value: 3
  },
  {
    text: '4季度',
    value: 4
  }
]
const { map: statusMap, setOps: setStatusOps } = mapHelper.setMap(status)
const { map: seasonNumMap, setOps: seasonNumOps } = mapHelper.setMap(seasonNum)

export { statusMap, setStatusOps, ruleData, seasonNumMap, seasonNumOps }
